@import '~@which/seatbelt/src/styles/styles';

.panel {
  margin-top: $sb-spacing-s;
  position: relative;
  overflow: hidden;

  border: sb-border($sb-border-width-1, 'sb-color-black-20');
  background-color: sb-color(sb-color-white);
  max-width: 100%;

  &Content{
    padding: $sb-spacing-m;
    
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.overlayContainer {
  position: relative;
  height: 100%;
  width: 100%;
  padding: $sb-spacing-s;
}

.benefitContent {
  width: 100%;
  margin-top: $sb-spacing-m
}

.headingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: $sb-spacing-s;
  margin-bottom: $sb-spacing-m;

  &Subheading {
    color: $sb-colour-background-brand-red
  }
}
