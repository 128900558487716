@import '~@which/seatbelt/src/styles/styles';

.list {
  @include sb-reset-list;
  column-gap: 60px;
  column-width: 195px;
  column-rule: 1px solid sb-color(sb-color-black-20);
  column-count: 1;
  padding-top: $sb-spacing-s;

  @include sb-respond-to(medium-up) {
    column-count: 3;
  }

  @include sb-respond-to(large) {
    column-gap: $sb-spacing-xl;
  }
}

.listItem {
  margin-bottom: $sb-spacing-m;
}
