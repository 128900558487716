@import '~@which/seatbelt/src/styles/styles';

.wrapper {
  padding: $sb-spacing-xl 0;

  @include sb-respond-to(large) {
    padding: $sb-spacing-xl 0 $sb-spacing-3xl;
  }
}

.header {
  padding-bottom: $sb-spacing-s;
}

.strapline {
  padding-bottom: $sb-spacing-m;

  @include sb-respond-to(large) {
    padding-bottom: $sb-spacing-l;
  }
}

.featuredLinks {
  &GridItem {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;

    @include sb-respond-to(medium-up) {
      max-width: revert;
      margin: revert;
    }
  }

  &ArticleCard {
    padding-top: $sb-spacing-s;
    margin-bottom: $sb-spacing-l;

    @include sb-respond-to(xlarge) {
      div[class^='FeaturedArticleCard_featuredArticleCardContent'] {
        display: flex;
      }

      div[data-testid='picture-container'] {
        flex: 1;
      }

      div[class^='FeaturedArticleCard_featuredArticleCardText'] {
        flex: 1;
      }

      div[data-testid='responsive-picture-placeholder'] {
        // 4:3 ratio
        padding-top: 75%
      }
    }
  }

  &LatestNews {
    ol[class^='LatestNews_latestNewsList'] {
      display: grid;
      gap: $sb-spacing-l $sb-spacing-m;

      @include sb-respond-to(medium-up) {
        grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
      }
    }

    li[class^='LatestNews_latestNewsListItem'] {
      margin-bottom: 0;
    }

    div[class*='SmallHorizontalArticleCard_smallHorizontalArticleCardPicture'] {
      max-width: 86px;
    }
  }
}

.memberBenefits {
  margin-top: $sb-spacing-s;
  border: sb-border($sb-border-width-1, 'sb-color-black-20');
  &, li[class^='MemberBenefits_listItem'], section[class^='MemberBenefits_overlay'] {
    background-color: sb-color(sb-color-white);
  }

  @include sb-respond-to(small) {
    margin-top: $sb-spacing-l;
  }
}
