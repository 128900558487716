@import '~@which/seatbelt/src/styles/styles';
@import "../../PersonalisedMemberBenefits.module.scss";

$benefits-icon-width: 50px;

.overlay {
  background-color: white;
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  padding-left: $sb-spacing-m;
  padding-right: $sb-spacing-m;
  overflow: auto;
  z-index: 1;
  transition: transform 250ms ease-in;
  transform: translate(calc(100% + $panel-padding));

  @include sb-respond-to(medium-up) {
    width: auto;
  }

  &Revealed {
    transition: transform 250ms ease-out;
    transform: translate(0);
  }
  
  // Disable transition on iOS Safari due to poor performance
  @supports (-webkit-touch-callout: none) {
    transition: none;
  }

  outline: none;
  outline-offset: 6px;

  &Button {
    padding: 0;
    margin-left: $sb-spacing-xs;
    display: flex;
    justify-content: center;
    align-items: center;

    $button-size: 36px;
    width: $button-size;
    height: $button-size;
    border-radius: calc($button-size / 2);

    &Icon {
      position: relative;
      left: 2px;
    }
  }

  &Header {
    display: flex;
    align-items: center;
    gap: $sb-spacing-m;
    margin-right: $sb-spacing-xs;
    padding-right: $sb-spacing-m;
    min-height: $benefits-icon-width;
    padding-bottom: $sb-spacing-s;
    border-bottom: 1px solid sb-color('sb-color-black-20');
  }

  &List {
    padding: $sb-spacing-m $sb-spacing-xl 0 $sb-spacing-s;

    @include sb-respond-to(medium-up) {
      padding: $sb-spacing-m $sb-spacing-m 0 $sb-spacing-s;
    }

    &Subheader {
      margin-bottom: $sb-spacing-m;
    }
  }
}
.icon {
  width: $benefits-icon-width;
  min-width: $benefits-icon-width;

  &Image {
    background-color: transparent;
  }
}

.benefitsRowText {
  line-height: 140%;
}
