@import '~@which/seatbelt/src/styles/styles';

$offer-icon-width: 50px;

.offerBox {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &Icon {
    flex-grow: 0;
    width: $offer-icon-width;
    margin-bottom: $sb-spacing-s;
  
    &Image {
      background-color: transparent;
    }
  }
}
