@import '~@which/seatbelt/src/styles/styles';

.section:not(:first-child) {
  border-top: 1px solid $sb-colour-border-emphasised;
  margin-top: $sb-spacing-xl;
  padding-top: $sb-spacing-xl;
}

.list {
  @include sb-reset-list;
  display: grid;
  gap: 15px;
  margin-bottom: $sb-spacing-l;
  padding-top: $sb-spacing-s;

  @include sb-respond-to(medium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include sb-respond-to(large) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.listItem {
  white-space: nowrap;
}

.link {
  display: flex;
  align-items: flex-end;
}

.linkText {
  margin-bottom: 3px;
}

.picture {
  margin-right: $sb-spacing-m;
  flex: 0 0 50px;
}
.navSection li {
  align-content: center;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100px;
  white-space: pre-wrap;
}

.navSection li a {
  align-content: center;
  align-items: center;
  height: 100%;
  margin: $sb-spacing-xs
    $sb-spacing-l;
}

.navSection li:hover {
  box-shadow: 2px 6px 6px -2px #ccc;
}

.navSection li a h3 {
  color: black;
  text-decoration: none;
}

.navSection li img {
  background-color: #fff;
}
