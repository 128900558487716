@import '~@which/seatbelt/src/styles/styles';

.overline {
  color: $sb-colour-text-negative;
  margin-bottom: $sb-spacing-xs	
}

.buttonCaption {
  margin-top: $sb-spacing-s
}

.offerContent {
  padding: $sb-spacing-m;
}

.offerBoxContainer {
  > article {
    overflow: hidden;
    padding: 0;

    .redBlock {
      height: 19px;
      background-color: $sb-colour-background-brand-red;
    }
  }
}
