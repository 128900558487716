@import '~@which/seatbelt/src/styles/styles';

$panel-padding: $sb-spacing-m;

.panel {
  position: relative;
  overflow: hidden;
  margin-top: $sb-spacing-s;

  @include sb-respond-to(small) {
    margin-top: $sb-spacing-l;
  }

  padding: $panel-padding;
  border: sb-border($sb-border-width-1, 'sb-color-black-20');
  background-color: sb-color(sb-color-white);

  &HeadingWrapper {
    display: flex;
    flex-direction: column;
  }
  
  &Title {
    margin-bottom: $sb-spacing-m;
  }

  &Section {
    margin-top: $sb-spacing-m;
  }
}

.callout {
  display: block;
  background-color: sb-color('sb-color-brand-blue-20');
  margin: 20px 0;
  padding: $sb-spacing-s;
}